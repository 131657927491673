<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :profile="profile"/>
        <div class="subheader">
           <h3>Faqs</h3>
        </div>
        <div class="container" style="margin-top:30px">
            <div class="row">
                <div class="col-md-10 col-sm-12">
                    <h5>1-¿Pueden emitir factura y tiene algún costo?</h5>
                    <p>El proceso de facturación es automático y no tiene costo adicional, los montos presentados en cada programa incluyen el IVA, nuestra empresa cumple con las regulaciones de acuerdo al Servicio de Administración Tributaria.</p>

                    <h5>2-¿Cuánto tiempo tengo disponible el curso?</h5>
                    <p>EL tiempo normal son 30 días naturales a partir de que te inscribiste a un curso. <!--mas 15 días naturales en caso de que requieras una oportunidad extra ordinaria de acreditación.--></p>

                    <!--<h5>3-¿Cuántas oportunidades tengo para acreditar el curso?</h5>
                    <p>Dos oportunidades ordinarias, en caso de que no acredites tendrás una oportunidad extraordinaria, debiendo presentar una carta motivo de la no acreditación al tutor, si aun así no acreditas solo recibirás una constancia de participación, mas no tu certificado.</p>-->

                    <h5>3-¿Mi certificado tiene trazabilidad?</h5>
                    <p>Claro, una vez que acredites recibirás tu certificado con un código de seguridad, el cual podrás scanear</p>

                   <!--<h5>5-¿El tutor asignado es una persona?</h5>
                    <p>Si, el tutor que se te asigne te acompañara en tu proceso de aprendizaje de forma remota para contestar tus dudas y evaluar tu proceso de acreditación.</p>

                    <h5>6-¿Cómo se aseguran que el tutor es competente?</h5>
                    <p>Para la selección de un tutor como colaborador evaluamos tres aspectos fundamentales, el primero es su formación académica, el segundo es sus certificaciones y por ultimo la experiencia en el tema en cuestión.</p>

                    <--!<h5>4-¿Por qué el programa es estricto en la evaluación del participante?</h5>
                    <p>Nuestra meta es que tengas las herramientas necesarias para poder hacer uso en casos reales, no solo es un programa teórico.</p>-->

                    <h5>4-¿Qué pasa si tengo un problema con la plataforma de aprendizaje?</h5>
                    <p>Nos hemos esforzado para que tengas una experiencia libre de cualquier dificultad, sin embargo tendrás a tu disposición tanto a el tutor para cuestiones de contenido, y a nuestra área de tecnología para cuestiones técnicas con la plataforma.</p>

                    <h5>5-¿Las empresas o instituciones que quieran implementar algún programa con sus colaboradores obtienen algún descuento?</h5>
                    <p>Si, previa firma de convenio de colaboración, los descuentos dependerán de la cantidad de participantes que quieran inscribir y la cantidad de programas a implementar. Solicitar información al correo <a href="mailto:contacto@tmtcapacitacion.com">contacto@tmtcapacitacion.com</a></p>

                    <h5>6-¿Cómo solicito un convenio de colaboración?</h5>
                    <p>Muy fácil, solicítalo al correo <a href="mailto:contacto@tmtcapacitacion.com">contacto@tmtcapacitacion.com</a>, y en un lapso de 48 hrs máximo recibirás respuesta.</p>

                    <h5>7-¿Puedo usar un dispositivo móvil para tomar el programa?</h5>
                    <p>Por supuesto, la plataforma esta diseñada para ser flexible incluso podrás modificar la resolución para que el consumo de datos disminuya.</p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
 components:{
     Header,
     Footer,

 },
  name: 'faqs',
  data() {
      return {
          profile: this.$store.getters.getUser
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p{ margin-bottom:40px; color:#878686}

.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}

.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px;
     background-image :url(../../assets/img/bg-faqs.jpg);
   background-repeat: no-repeat;
  background-size: cover;
  }
</style>
